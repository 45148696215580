.sticky-header {
  position: sticky !important;
  top: 0;
  z-index: 1;
  background-color: var(--elevation-secondary);

  @media (prefers-color-scheme: dark) {
    background-color: $elevation-dark-00-dp !important;
  }
}

.empty-header {
  font-family: "Raleway";
  font-size: 24.3px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.conditions-header-text {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;
  font-family: "Raleway";
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

.banner-header {
  position: relative;
  border-bottom: 1px solid $new-primary-blue;
  background-color: var(--elevation-secondary);

  // elevation-dark-00-dp
  @media (prefers-color-scheme: dark) {
    background-color: $elevation-dark-00-dp !important;
  }

  .back-icon {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .add-icon {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
