.mat-mdc-dialog-container {
  position: relative;
}

.mat-mdc-dialog-actions .mat-mdc-button-base+.mat-mdc-button-base {
  margin-left: 16px !important;
}

.dialog-header {
  top: -24px;
  position: sticky !important;
  top: -24px;
  z-index: 1;
  background-color: var(--elevation-secondary);
  margin: -24px -24px 8px;
  padding: 16px 24px;

  &.border-bottom {
    border-bottom: 1px solid var(--opacity-12);
  }
}
