.mat-bottom-sheet-container {
  background-color: var(--elevation-primary) !important;
}

.desktop-settings-sheet {
  padding: 0 !important;
  height: 100vh !important;
  max-height: 100% !important;
}

@supports (-webkit-touch-callout: none) {
  /* CSS for iOS devices */
  .settings-sheet {
    padding: 0 !important;
    height: 100vh !important;
    max-height: 100% !important;
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  .settings-sheet {
    padding: 0 !important;
    height: 100vh !important;
    position: fixed;
    top: 0;
    left: 0;
    max-height: 100% !important;
  }
}

.sheet-header {
  padding: 15px;
  border-bottom: 1px solid var(--opacity-12);
  margin-bottom: 24px;
  background-color: var(--elevation-tertiary) !important;

  @media (prefers-color-scheme: dark) {
    padding: 16px;
    border-bottom: none;
  }
}

.sheet-body {
  padding: 0 16px;
}

button.mat-button.delete-btn {
  color: var(--error-color);
}

.tall-bottom-sheet,
.typeahead-bottom-sheet {
  height: 100vh;
  padding: 0 !important;
  max-height: 100vh !important;
}

.cdk-global-scrollblock {
  position: static; // fixes white bg showing up in dark mode
}
