h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  display: inline-block;
  font-family: "Poppins";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
}

h1 {
  font-size: 97px;
  font-weight: 300;
}

h2,
h4,
.subtitle-1,
.subtitle-2 {
  font-family: "Space Grotesk";
}

h2 {
  font-size: 60px;
  font-weight: 300;
}

h3 {
  font-size: 28px;
  font-weight: 300;
}

h4 {
  font-size: 34px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}

.medium {
  color: var(--opacity-60);
}

.high {
  color: var(--opacity-87) !important;
}

.muted-text {
  font-weight: 400;
  color: var(--opacity-54);
  letter-spacing: 0.25px;
}

.btn-spacing {
  line-height: 1.13;
  letter-spacing: 1.25px;
}

.subtitle-1 {
  letter-spacing: 0.2px;
  line-height: 1.44;
  letter-spacing: 0.25px;
}

.subtitle-2 {
  line-height: 1.64;
  letter-spacing: 0.5px;
}

.body-1 {
  line-height: 1.68;
  letter-spacing: 0.5px;
}

.body-2 {
  line-height: 1.37;
  letter-spacing: 0.5px;
}

.body-3 {
  font-weight: 400;
  line-height: 16px;
}

.caption {
  color: var(--opacity-60);
  font-family: "Poppins";
  letter-spacing: 0.4px;
}

.empty-text {
  margin: 16px;
  font-size: 14.6px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--opacity-60);
}

// icons
.icon-24 {
  height: 24px;
  width: 24px;
  font-size: 24px;
}

.raleway {
  font-family: "Poppins";
}

.lato {
  font-family: "Poppins";
}
