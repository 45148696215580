@mixin backgroundShorthand($imgpath, $position:0 0, $repeat: no-repeat) {
  background: transparent url(#{$imgpath}) $repeat $position;
}

// Generates power of 4(default) margin class with the name '$name'-(&i * $multi)
//   Ex: @include margin-maker(mb, bottom, 1, 1);
//    Generates
//      .mb-4 { margin-bottom: 4px !important }
//   $name: prefix to apply in combination with calculated power of 8 for class name
//   $direction: type of margin
//   $start: starting index to use for margin calculation default of 1 would start with 4px margin 
//   $end: ending index to use for margin calculation default of 4 would end with 16px margin
//
@mixin margin-maker($name, $direction, $start:-16, $end:16, $multi:2){
  @for $i from $start through $end {
    $size: $i * $multi;
    .#{$name}-#{$size}{
      @if $direction == x {
        margin-left: #{$size}px !important;
        margin-right: #{$size}px !important;
      } @else if $direction == y {
        margin-top: #{$size}px !important;
        margin-bottom: #{$size}px !important;
      } @else if $direction == all {
        margin:#{$size}px !important;
      } @else {
        margin-#{$direction}:#{$size}px !important;
      }
    }
  }
}
@include margin-maker(mr, right);
@include margin-maker(ml, left);
@include margin-maker(mb, bottom);
@include margin-maker(mt, top);
@include margin-maker(mx, x);
@include margin-maker(my, y);
@include margin-maker(m, all);

// Generates power of 4(default) padding class with the name '$name'-(&i * $multi)
//   Ex: @include padding-maker(mb, bottom, 1, 1);
//    Generates
//      .mb-4 { padding-bottom: 4px !important }
//   $name: prefix to apply in combination with calculated power of 8 for class name
//   $direction: type of padding
//   $start: starting index to use for padding calculation default of 1 would start with 4px padding 
//   $end: ending index to use for padding calculation default of 4 would end with 16px padding
//
@mixin padding-maker($name, $direction, $start:-16, $end:16, $multi:2){
  @for $i from $start through $end {
    $size: $i * $multi;
    .#{$name}-#{$size}{
      @if $direction == x {
        padding-left: #{$size}px !important;
        padding-right: #{$size}px !important;
      } @else if $direction == y {
        padding-top: #{$size}px !important;
        padding-bottom: #{$size}px !important;
      } @else if $direction == all {
        padding:#{$size}px !important;
      } @else {
        padding-#{$direction}:#{$size}px !important;
      }
    }
  }
}
@include padding-maker(pr, right);
@include padding-maker(pl, left);
@include padding-maker(pb, bottom);
@include padding-maker(pt, top);
@include padding-maker(px, x);
@include padding-maker(py, y);
@include padding-maker(p, all);
