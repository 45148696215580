// New colors
$icons-error-light: #db2639;
$rosa: #f29aa0;
$off-white: #fef9e2;
$pale-peach: #feeeb5;
$wheat: #fde386;
$light-gold: #fdda55;
$sun-yellow: #fdcf31;
$golden-yellow: #fdc71b;
$orangey-yellow: #fdb913;
$yellowish-orange: #fda60e;
$tangerine: #fd960a;
$pumpkin-orange: #fd7704;
$ice: #e5f5ee;
$pale-turquoise: #c0e6d4;
$light-teal: #97d6b9;
$soft-green: #6ac79d;
$dark-mint: #45bb89;
$green-teal: #08af75;
$emerald: #00a06a;
$emerald-two: #008e5c;
$jungle-green: #007c50;
$dark-blue-green: #005d3a;
$very-light-blue: #d8f2ff;
$powder-blue: #b7ddfa;
$cloudy-blue: #9bc4e4;
$bluegrey: #7baace;
$faded-blue: #6396bd;
$dirty-blue: #4883ac;
$muted-blue: #39749a;
$light-navy-blue: #296082;
$dark-slate-blue: #1a4d6c;
$midnight-blue-summer: #052a5c;
$dark-teal: #003853;
$ice-blue: #dbfcfb;
$robins-egg-blue: #a4f7f5;
$bright-cyan: #54f2f2;
$aqua-blue: #00eaee;
$aqua-blue-two: #00e2e9;
$aqua-blue-three: #00dbe9;
$aqua-blue-four: #00cad5;
$turquoise-blue: #00b3b9;
$teal-blue: #009fa1;
$bluegreen: #007a72;
$spruce: #0d4a47;
$grey-lilac: #f2f4f7;
$pale-lilac: #e3e4eb;
$cloudy-blue-two: #b9bcce;
$bluey-grey: #8d91ad;
$grey-blue: #63688c;
$dusk: #464b77;
$dark-grey-blue: #292f63;
$dark-blue-grey: #24295c;
$dark-blue-grey-two: #1d2152;
$dark-blue-grey-three: #151846;
$dark-indigo: #0d0630;
$dark-grey-lilac: #b9bcd0;
$light-pink: #fbe8f3;
$pastel-pink: #f7c5e2;
$bubblegum-pink: #f5a1cf;
$bubblegum: #f57cbb;
$medium-pink: #f462a9;
$barbie-pink: #f74e98;
$darkish-pink: #e44a91;
$darkish-pink-two: #cc4688;
$dark-rose: #b54281;
$bruise: #8d3b72;
$brown-grey: #979797;
$white: #ffffff;
$elevation-shadow-00-dp: #fafafa;
$black: #000000;
$pale-grey-two: #fffcff;
$pale-grey: #fbffff;
$pale-grey-three: #effaff;
$pale-grey-four: #f9fdfb;
$grape: #542b47;
$pine-green: #0d3c2b;
$new-primary-blue: #0358cb;

$elevation-dark-00-dp: #121212;

$black-87-opacity: rgba(black, 0.87);
$black-60-opacity: rgba(black, 0.6);
$black-54-opacity: rgba(black, 0.54);
$black-38-opacity: rgba(black, 0.38);
$black-26-opacity: rgba(black, 0.26);
$black-20-opacity: rgba(black, 0.2);
$black-12-opacity: rgba(black, 0.12);
$black-06-opacity: rgba(black, 0.06);
$black-03-opacity: rgba(black, 0.03);

$white-87-opacity: rgba(white, 0.87);
$white-60-opacity: rgba(white, 0.6);
$white-54-opacity: rgba(white, 0.54);
$white-38-opacity: rgba(white, 0.38);
$white-26-opacity: rgba(white, 0.26);
$white-20-opacity: rgba(white, 0.2);
$white-12-opacity: rgba(white, 0.12);
$white-06-opacity: rgba(white, 0.06);
$white-03-opacity: rgba(white, 0.03);

// Light colors
$light-mode-bg-color: $white;
$light-mode-text-color: $black-87-opacity;
$light-mode-link-color: $muted-blue;
$light-mode-text-med-color: $black-60-opacity;
$light-mode-text-disabled-color: $black-38-opacity;
$light-mode-error-color: $icons-error-light;
$light-mode-text-secondary-color: $dark-teal;
$light-mode-primary-color: $emerald-two;
$light-mode-primary-dark-color: $jungle-green;
$light-mode-shine-gradient-color: $pale-lilac;
$light-mode-shine-gradient-secondary-color: $grey-lilac;

// Elevation
$elevation-light-tertiary: $white;
$elevation-light-secondary: $white;
$elevation-light-primary: #fcfbfb;
$elevation-dark-tertiary: #2c2c2e;
$elevation-dark-secondary: #1c1c1e;
$elevation-dark-primary: $black;

// Dark colors
$dark-mode-bg-color: $elevation-dark-primary;
$dark-mode-text-color: $white-87-opacity;
$dark-mode-link-color: $cloudy-blue;
$dark-mode-text-med-color: $white-60-opacity;
$dark-mode-text-disabled-color: $white-38-opacity;
$dark-mode-error-color: $rosa;
$dark-mode-text-secondary-color: $grey-blue;
$dark-mode-primary-color: $soft-green;
$dark-mode-primary-dark-color: $emerald-two;
$dark-mode-shine-gradient-color: #28293E;
$dark-mode-shine-gradient-secondary-color: $elevation-dark-secondary;



// New Cix Colors
$purple-10: #FBDAFD;
$purple-20: #DDB2FF;
$purple-40: #9A43FF;
$purple-50: #7600FF;
$purple-70: #5000AD;
$purple-80: #041627;

$blue-10: #DBE7F7;
$blue-20: #9ABCEA;
$blue-40: #4390F8;
$blue-50: #0358CB;
$blue-70: #003377;
$blue-80: #041627;


$teal-10: #BDDFE3;
$teal-20: #9CD0D6;
$teal-40: #5AB1BB;
$teal-50: #297F88;
$teal-70: #19585F;

$green-10: #E0F6E3;
$green-20: #B9EEC0;
$green-40: #7CCA86;
$green-50: #008E5C;
$green-70: #034F34;

$yellow-10: #FFF9DB;
$yellow-20: #FFECA7;
$yellow-40: #FFDD60;
$yellow-50: #FFC300;
$yellow-70: #D29700;

$coral-10: #FFE8E5;
$coral-20: #FFBFB5;
$coral-40: #FF8774;
$coral-50: #FF6047;
$coral-70: #A51D08;

$pink-10: #FFE8EF;
$pink-20: #FFBFD4;
$pink-40: #FF6095;
$pink-50: #FF009C;
$pink-70: #C0057F;


body.light-mode {
  --bg-color: #{$light-mode-bg-color};
  --text-color: #{$light-mode-text-color};
  --link-color: #{$light-mode-link-color};
  --text-med-color: #{$light-mode-text-med-color};
  --text-disabled-color: #{$light-mode-text-disabled-color};
  --error-color: #{$light-mode-error-color};
  --text-secondary-color: #{$light-mode-text-secondary-color};
  --primary-color: #{$light-mode-primary-color};
  --primary-dark-color: #{$light-mode-primary-dark-color};
  --shine-gradient-color: #{$light-mode-shine-gradient-color};
  --shine-gradient-secondary-color: #{$light-mode-shine-gradient-secondary-color};

  --white-to-black: #{$white};
  --black-to-white: #{$black};
  --stepper-green: #{$green-teal};
  --stepper-turquoise-green: #{$pale-turquoise};
  --stepper-turquoise-gray: #{$pale-turquoise};
  --stepper-green-turquoise: #{$green-teal};
  --grey-lilac: #{$grey-lilac};
  --cloudy-blue-two: #{$cloudy-blue-two};
  --very-light-blue: #{$very-light-blue};

  --opacity-87: #{$black-87-opacity};
  --opacity-60: #{$black-60-opacity};
  --opacity-54: #{$black-54-opacity};
  --opacity-38: #{$black-38-opacity};
  --opacity-26: #{$black-26-opacity};
  --opacity-20: #{$black-20-opacity};
  --opacity-12: #{$black-12-opacity};
  --opacity-06: #{$black-06-opacity};
  --opacity-03: #{$black-03-opacity};


  // new colors
  --coral-20: #{$coral-20};
  --coral-40:#{$coral-40};
  --coral-50: #{$coral-50};
  --coral-70: #{$coral-70};

  --green-20: #{$green-20};
  --green-40:#{$green-40};
  --green-50: #{$green-50};
  --green-70: #{$green-70};

  --blue-10: #{$blue-10};
  --blue-20: #{$blue-20};
  --blue-40:#{$blue-40};
  --blue-50: #{$blue-50};
  --blue-70: #{$blue-70};

  --pink-20: #{$pink-20};
  --pink-40:#{$pink-40};
  --pink-50: #{$pink-50};
  --pink-70: #{$pink-70};

  --purple-20: #{$purple-20};
  --purple-40:#{$purple-40};
  --purple-50: #{$purple-50};
  --purple-70: #{$purple-70};

  --teal-20: #{$teal-20};
  --teal-40:#{$teal-40};
  --teal-50: #{$teal-50};
  --teal-70: #{$teal-70};

  --yellow-20: #{$yellow-20};
  --yellow-40:#{$yellow-40};
  --yellow-50: #{$yellow-50};
  --yellow-70: #{$yellow-70};

  --elevation-primary: #{$elevation-light-primary};
  --elevation-secondary: #{$elevation-light-secondary};
  --elevation-tertiary: #{$elevation-light-tertiary};

  --employer-pink: #{$pink-20};
}

body.dark-mode {
  --bg-color: #{$dark-mode-bg-color};
  --text-color: #{$dark-mode-text-color};
  --link-color: #{$dark-mode-link-color};
  --text-med-color: #{$dark-mode-text-med-color};
  --text-disabled-color: #{$dark-mode-text-disabled-color};
  --error-color: #{$dark-mode-error-color};
  --text-secondary-color: #{$dark-mode-text-secondary-color};
  --primary-color: #{$dark-mode-primary-color};
  --primary-dark-color: #{$dark-mode-primary-dark-color};
  --shine-gradient-color: #{$dark-mode-shine-gradient-color};
  --shine-gradient-secondary-color: #{$dark-mode-shine-gradient-secondary-color};

  --white-to-black: #{$black};
  --black-to-white: #{$white};
  --stepper-green: #{$dark-mint};
  --stepper-turquoise-green: #{$dark-mint};
  --stepper-turquoise-gray: #{$white-60-opacity};
  --stepper-green-turquoise: #{$pale-turquoise};
  --grey-lilac: #{$cloudy-blue-two};
  --cloudy-blue-two: #{$grey-lilac};
  --very-light-blue: #{$spruce};

  --employer-pink: #{$pink-20};
  // dark mode for new colors
  --coral-20: #{$coral-50};
  --coral-40:#{$coral-40};
  --coral-50: #{$coral-20};
  --coral-70: #{$coral-10};

  --green-20: #{$green-50};
  --green-40:#{$green-40};
  --green-50: #{$green-20};
  --green-70: #{$green-10};

  --blue-10: #{$blue-70};
  --blue-20: #{$blue-50};
  --blue-40:#{$blue-40};
  --blue-50: #{$blue-20};
  --blue-70: #{$blue-10};


  --pink-20: #{$pink-50};
  --pink-40:#{$pink-40};
  --pink-50: #{$pink-20};
  --pink-70: #{$pink-10};

  --purple-20: #{$purple-50};
  --purple-40:#{$purple-40};
  --purple-50: #{$purple-20};
  --purple-70: #{$purple-10};

  --teal-20: #{$teal-50};
  --teal-40:#{$teal-40};
  --teal-50: #{$teal-20};
  --teal-70: #{$teal-10};

  --yellow-20: #{$yellow-50};
  --yellow-40:#{$yellow-40};
  --yellow-50: #{$yellow-20};
  --yellow-70: #{$yellow-10};

  --opacity-87: #{$white-87-opacity};
  --opacity-60: #{$white-60-opacity};
  --opacity-54: #{$white-54-opacity};
  --opacity-38: #{$white-38-opacity};
  --opacity-26: #{$white-26-opacity};
  --opacity-20: #{$white-20-opacity};
  --opacity-12: #{$white-12-opacity};
  --opacity-06: #{$white-06-opacity};
  --opacity-03: #{$white-03-opacity};

  --elevation-primary: #{$elevation-dark-primary};
  --elevation-secondary: #{$elevation-dark-secondary};
  --elevation-tertiary: #{$elevation-dark-tertiary};
}
