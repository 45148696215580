@import "./cxcolors";

.dark-teal {
  color: var(--text-secondary-color) !important;
}

.align-middle {
  vertical-align: middle;
  display: inline-block;
}

.full-width {
  width: 100%;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.flex-center {
  justify-content: center;
  align-content: center;
  justify-self: center;
  justify-items: center;
  align-items: center;
  align-self: center;
}

.employer-submit-btn {
  background-color: #0358cb !important;
}

.single-flex {
  flex: 1;
}


.muted-blue {
  color: #0358cb;
}

.icon-16 {
  font-size: 16px;
  height: 16px !important;
  width: 16px !important;
}

.icon-14 {
  font-size: 14px;
  height: 14px !important;
  width: 14px !important;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.vertical-middle {
  vertical-align: middle;
}

.vertical-text-bottom {
  vertical-align: text-bottom;
}

.suffix {
  color: var(--opacity-60);
}

.flex-end {
  justify-self: flex-end;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center-flex {
  justify-items: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
}

.eyeball {
  color: var(--opacity-38);
  cursor: pointer;
}

.clickable {
  cursor: pointer !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.center-row {
  text-align: center;
  font-size: 16px;
}

.button-top-margin {
  margin-top: 24px !important;
}

.inline {
  display: inline-block;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.number-text {
  font-family: "Lato";
  font-size: 16px;
  color: var(--text-med-color);
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.titlecase {
  text-transform: capitalize;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.center {
  text-align: center;
}

.center-block {
  margin: 0 auto;
  display: block;
}

.center-inline-block {
  margin: 0 auto;
  display: inline-block;
}

.auto-margin {
  margin: auto;
}

.hidden {
  visibility: hidden;
}

.no-overflow-x {
  overflow-x: hidden;
}

// allows a button on dialog to be at the top of the container if need be
.dialog-allow-overflow .mat-dialog-container {
  overflow: visible;
}

.img-row {
  text-align: center;
  margin-top: 72px;
  margin-bottom: 32px;
}

.relative {
  position: relative;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
