// smart app banner
.smartbanner-show {
  margin-top: 80px;
}

.smartbanner-show .smartbanner {
  display: block;
}

/** Default **/
.smartbanner {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 80px;
  line-height: 80px;
  font-family: "Helvetica Neue", sans-serif;
  background: var(--bg-color);
  z-index: 1000;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  -webkit-text-size-adjust: none;
}

.smartbanner-container {
  margin: 0 auto;
  white-space: nowrap;
}

.smartbanner-close {
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 5px;
  font-family: "ArialRoundedMTBold", Arial;
  font-size: 20px;
  text-align: center;
  color: var(--opacity-60);
  text-decoration: none;
  border: 0;
  border-radius: 14px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.smartbanner-close:active,
.smartbanner-close:hover {
  color: var(--opacity-38);
}

.smartbanner-icon {
  display: inline-block;
  vertical-align: middle;
  width: 57px;
  height: 57px;
  margin-right: 12px;
  background-size: cover;
  border-radius: 10px;
}

.smartbanner-info {
  display: inline-block;
  vertical-align: middle;
  width: 44%;
  font-size: 11px;
  line-height: 1.2em;
  font-weight: bold;
}

.smartbanner-title {
  font-size: 13px;
  line-height: 18px;
}

.smartbanner-button {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: 0 1px 0 var(--opacity-03);
}

.smartbanner-button:active,
.smartbanner-button:hover {
  color: var(--opacity-38);
}

/** iOS **/
.smartbanner-ios {
  background: var(--bg-color);
  background: linear-gradient(to bottom, var(--bg-color), var(--opacity-26));
  box-shadow: 0 1px 2px var(--opacity-54);
  line-height: 80px;
}

.smartbanner-ios .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: var(--opacity-60);
  text-shadow: 0 1px 0 var(--opacity-03);
}

.smartbanner-ios .smartbanner-close:active,
.smartbanner-ios .smartbanner-close:hover {
  color: var(--opacity-38);
}

.smartbanner-ios .smartbanner-icon {
  background: var(--opacity-60);
  background-size: cover;
  box-shadow: 0 1px 3px var(--opacity-26);
}

.smartbanner-ios .smartbanner-info {
  text-shadow: 0 1px 0 var(--opacity-03);
}

.smartbanner-ios .smartbanner-title {
  color: var(--opacity-60);
  font-weight: bold;
}

.smartbanner-ios .smartbanner-button {
  padding: 0 10px;
  min-width: 10%;

  background: transparent;
  background: linear-gradient(to bottom, transparent, var(--opacity-26));
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px var(--opacity-26), 0 1px 0 var(--opacity-60),
    0 2px 0 var(--opacity-60) inset;
}

.smartbanner-ios .smartbanner-button:active,
.smartbanner-ios .smartbanner-button:hover {
  background: var(--opacity-26);
  background: linear-gradient(to bottom, var(--opacity-26), transparent);
}

/** Android **/
.smartbanner-android {
  background: $black url(data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7);
  box-shadow: inset 0 4px 0 var(--primary-color);
  line-height: 82px;
}

.smartbanner-android .smartbanner-close {
  border: 0;
  width: 17px;
  height: 17px;
  line-height: 17px;
  margin-right: 7px;
  color: var(--opacity-26);
  background: var(--opacity-87);
  text-shadow: 0 1px 1px var(--opacity-87);
  box-shadow: 0 1px 2px var(--opacity-87) inset,
    0 1px 1px var(--opacity-38);
}

.smartbanner-android .smartbanner-close:active,
.smartbanner-android .smartbanner-close:hover {
  color: var(--opacity-06);
}

.smartbanner-android .smartbanner-icon {
  background-color: transparent;
  box-shadow: none;
}

.smartbanner-android .smartbanner-info {
  color: var(--opacity-26);
  text-shadow: 0 1px 2px var(--opacity-87);
}

.smartbanner-android .smartbanner-title {
  color: var(--opacity-03);
  font-weight: bold;
}

.smartbanner-android .smartbanner-button {
  min-width: 12%;
  color: var(--opacity-26);
  padding: 0;
  background: none;
  border-radius: 0;
  box-shadow: 0 0 0 1px $black, 0 0 0 2px var(--opacity-12);
}

.smartbanner-android .smartbanner-button:active,
.smartbanner-android .smartbanner-button:hover {
  background: none;
}

.smartbanner-android .smartbanner-button-text {
  text-align: center;
  display: block;
  padding: 0 10px;
  background: $aqua-blue;
  background: linear-gradient(to bottom, $aqua-blue-three, $aqua-blue-four);
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
}

.smartbanner-android .smartbanner-button-text:active,
.smartbanner-android .smartbanner-button-text:hover {
  background: $aqua-blue-two;
}

/** Windows **/
.smartbanner-windows {
  background: var(--bg-color);
  background: linear-gradient(to bottom, var(--bg-color), var(--opacity-26));
  box-shadow: 0 1px 2px var(--opacity-54);
  line-height: 80px;
}

.smartbanner-windows .smartbanner-close {
  border: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: var(--opacity-60);
  text-shadow: 0 1px 0 var(--opacity-03);
}

.smartbanner-windows .smartbanner-close:active,
.smartbanner-windows .smartbanner-close:hover {
  color: var(--opacity-38);
}

.smartbanner-windows .smartbanner-icon {
  background: var(--opacity-60);
  background-size: cover;
  box-shadow: 0 1px 3px var(--opacity-26);
}

.smartbanner-windows .smartbanner-info {
  text-shadow: 0 1px 0 var(--opacity-03);
}

.smartbanner-windows .smartbanner-title {
  color: var(--opacity-60);
  font-weight: bold;
}

.smartbanner-windows .smartbanner-button {
  padding: 0 10px;
  min-width: 10%;
  background: transparent;
  background: linear-gradient(to bottom, transparent, var(--opacity-26));
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px var(--opacity-26), 0 1px 0 var(--opacity-60),
    0 2px 0 var(--opacity-60) inset;
}

.smartbanner-windows .smartbanner-button:active,
.smartbanner-windows .smartbanner-button:hover {
  background: var(--opacity-26);
  background: linear-gradient(to bottom, var(--opacity-26), transparent);
}