.mat-mdc-slide-toggle-label {
  color: var(--opacity-60);
  font-weight: 500;
  font-family: "Lato";
  font-size: 16px;
}

.mat-mdc-form-field-prefix,
.mat-mdc-form-field-suffix {
  color: var(--opacity-60);
}

.mdc-checkbox-layout {
  white-space: pre-wrap !important;
}

.mdc-checkbox {
  color: var(--opacity-54) !important;
}

.mat-mdc-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline-thick {
  opacity: 0;
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: var(--opacity-60) !important;
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-disabled .mat-mdc-form-field-label {
  color: var(--opacity-60) !important;
}

.form-field {
  width: calc(100% - 16px);
}

.dark-mode .mat-form-field.mat-focused .mat-form-field-label,
.dark-mode .mat-form-field.mat-focused .mat-form-field-ripple {
  // color: $cloudy-blue !important;
}

// special case for typeahead on desktop
@media (min-width: 500px) {
  .typeahead-bottom-sheet {
    width: 500px;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: var(--text-color) !important;

  @media (prefers-color-scheme: dark) {
    -webkit-box-shadow: 0 0 0 30px black inset !important;
  }
}
