// dialogs
.cdk-overlay-pane {
  max-width: 100vw !important;
  overflow-x: hidden;
}

.mat-mdc-dialog-container {
  &>* {
    // max-width: 800px;
    display: block;
    margin: auto auto;
  }
}

.mat-mdc-dialog-surface {
  padding: 24px;
}
