.mdc-button {
  // border-radius: 24px !important;
  letter-spacing: 1.25px;
  font-family: "Raleway";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  font-size: 16px;
  text-align: center;
}

.action {
  cursor: pointer;
  color: var(--opacity-54);
}

.link-button {
  color: var(--link-color);
  cursor: pointer;
  letter-spacing: 1px;
}

.mat-mdc-stroked-button.mat-mdc-warn.delete-button {
  mat-icon {
    color: var(--error-color);
  }
}

.empty-state-btn {
  margin-top: 16px;
  margin-bottom: 64px;
}

.mat-mdc-raised-button,
.mat-mdc-stroked-button,
.mat-mdc-unelevated-button,
.mdc-button,
.mat-mdc-flat-button {
  height: 40px !important;
  border-radius: 8px !important;
  width: 100%;
  letter-spacing: 1.25px !important;
  font-family: "Raleway";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13 !important;
  font-size: 16px;
  text-align: center;
}

.mat-primary {
  color: $white-87-opacity !important;
}

.mat-primary[disabled] {
  color: $white-38-opacity !important;
}

.mat-mdc-raised-button[disabled],
.mat-mdc-stroked-button[disabled],
.mat-mdc-flat-button[disabled],
.mdc-button[disabled],
.mat-mdc-unelevated-button[disabled] {
  color: var(--opacity-38);
}

// text color of accent buttons
body.light-mode .mat-mdc-flat-button.mat-accent,
body.light-mode .mat-mdc-raised-button.mat-accent,
body.light-mode .mat-mdc-fab.mat-accent,
body.light-mode .mat-mdc-mini-fab.mat-accent {
  color: $black-87-opacity;
}
