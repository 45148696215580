.card {
  cursor: default;
  padding: 16px;
  background-color: var(--elevation-secondary);
  border: 1px solid var(--opacity-12);
  border-radius: 6px;

  @media (prefers-color-scheme: dark) {
    border: 1px solid var(--elevation-secondary);
  }
}

.mat-mdc-card {
  background-color: var(--elevation-secondary) !important;
  border: 1px solid var(--opacity-12);
  border-radius: 6px;
  padding: 16px;

  @media (prefers-color-scheme: dark) {
    border: 1px solid var(--elevation-secondary);
  }
}

.callout-card-rose {
  padding: 16px;
  border-radius: 6px;

  background-color: rgba($darkish-pink-two, 0.05);
  border: 1px solid $dark-rose;

  @media (prefers-color-scheme: dark) {
    background-color: rgba($darkish-pink-two, 0.15);
  }
}

.callout-card-primary-elevation {
  padding: 16px;
  border-radius: 6px;
  background-color: var(--elevation-secondary);
}
