// @use '@angular/material'as mat;
// @import url("https://fonts.googleapis.com/css?family=Space+Grotesk:300,400,500,700&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500&display=swap");
// mat.$theme-ignore-duplication-warnings: true;

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.


// $custom-typography-config: mat.m2-define-typography-config($font-family: "Poppins",
//   $caption: mat.m2-define-typography-level(12px, 20px, 400),
//   $headline-1: mat.m2-define-typography-level(1.75rem, 2rem, 900, "Poppins"),
//   $headline-2: mat.m2-define-typography-level(1.75rem, 2rem, 700, "Space Grotesk"),
//   $button: mat.m2-define-typography-level(16px, 14px, 500, "Poppins"),
//   $subtitle-1: mat.m2-define-typography-level(16px, 24px, 400, "Space Grotesk"),
//   $subtitle-2: mat.m2-define-typography-level(16px, 28px, 400, "Space Grotesk"),
//   $body-1: mat.m2-define-typography-level(16px, 24px, 500),
//   $body-2: mat.m2-define-typography-level(14px, 20px, 500));

// // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
// //  The following line adds:
// //    1. Default typography styles for all components
// //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
// //  If you specify typography styles for the components you use elsewhere, you should delete this line.
// //  If you don't need the default component typographies but still want the hierarchy styles,
// //  you can delete this line and instead use:
// //    `@include mat.legacy-typography-hierarchy($custom-typography);`
// // @include mat.all-legacy-component-typographies($custom-typography-config);
// // @include mat.legacy-typography-hierarchy($custom-typography);
// // @include mat.legacy-core();
// @include mat.core();
// @include mat.all-component-typographies($custom-typography-config);


// $mat-cixteal: (50 : #FFFFFF,
//   100 : #BDDFE3,
//   200 : #9CD0D6,
//   300 : #9CD0D6,
//   400 : #5AB1BB,
//   500 : #297F88,
//   600 : #247780,
//   700 : #19585F,
//   800 : #19585F,
//   900 : #121212,
//   A100 : #F5F9FF,
//   A200 : #5ce9ff,
//   A400 : #29e2ff,
//   A700 : #0fdeff,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   ));

// $mat-cixblue: (50 : #FFFFFF,
//   100 : #DBE7F7,
//   200 : #9ABCEA,
//   300 : #4f8adb,
//   400 : #4390F8,
//   500 : #0358CB,
//   600 : #0358CB,
//   700 : #003377,
//   800 : #041627,
//   900 : #121212,
//   A100 : #DBE7F7,
//   A200 : #a2b4ff,
//   A400 : #6f8aff,
//   A700 : #5675ff,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #ffffff,
//     400 : #ffffff,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #ffffff,
//     A200 : #ffffff,
//     A400 : #ffffff,
//     A700 : #ffffff,
//   ));

// $mat-cixred: (50 : #FFFFFF,
//   100 : #FFE8E5,
//   200 : #FFBFB5,
//   300 : #e66774,
//   400 : #FF8774,
//   500 : #FF6047,
//   600 : #FF6047,
//   700 : #A51D08,
//   800 : #A51D08,
//   900 : #121212,
//   A100 : #FFF0EE,
//   A200 : #ffbec0,
//   A400 : #ff8b8f,
//   A700 : #ff7176,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #ffffff,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   ));

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/

// $light-primary: mat.m2-define-palette($mat-cixblue);
// $light-accent: mat.m2-define-palette($mat-cixteal);

// // The warn palette is optional (defaults to red).
// $light-warn: mat.m2-define-palette($mat-cixred);

// // Create the theme object (a Sass map containing all of the palettes).
// // $light-theme: mat.define-light-theme($light-primary, $light-accent, $light-warn);
// $light-theme: mat.m2-define-light-theme((color: (primary: $light-primary,
//       accent: $light-primary,
//       warn: $light-warn,
//       teal: $light-accent),
//     typography: $custom-typography-config,
//     density: 0,
//   ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// body.light-mode {
//   // @include mat.all-legacy-component-themes($light-theme);
//   @include mat.all-component-themes($light-theme);

//   background-color: $elevation-shadow-00-dp;
//   color: var(--text-color)
// }


// $mat-dark-cixred: (50 : #121212,
//   100 : #FF6047,
//   200 : #FF8774,
//   300 : #FF8774,
//   400 : #FFBFB5,
//   500 : #FFBFB5,
//   600 : #FFBFB5,
//   700 : #FFF0EE,
//   800 : #FFF0EE,
//   900 : #FFFFFF,
//   A100 : #A51D08,
//   A200 : #A51D08,
//   A400 : #fff8f9,
//   A700 : #ffdfe0,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #000000,
//     600 : #000000,
//     700 : #000000,
//     800 : #000000,
//     900 : #000000,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   ));


// $mat-dark-cixteal: (50 : #121212,
//   100 : #19585F,
//   200 : #19585F,
//   300 : #247780,
//   400 : #5AB1BB,
//   500 : #297F88,
//   600 : #9CD0D6,
//   700 : #9CD0D6,
//   800 : #BDDFE3,
//   900 : #FFFFFF,
//   A100 : #0fdeff,
//   A200 : #29e2ff,
//   A400 : #5ce9ff,
//   A700 : #F5F9FF,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #000000,
//     600 : #000000,
//     700 : #000000,
//     800 : #000000,
//     900 : #000000,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   ));

// $mat-dark-cixblue: (50 : #121212,
//   100 : #003377,
//   200 : #0358CB,
//   300 : #0358CB,
//   400 : #4390F8,
//   500 : #9ABCEA,
//   600 : #9ABCEA,
//   700 : #DBE7F7,
//   800 : #F5F9FF,
//   900 : #FFFFFF,
//   A100 : #041627,
//   A200 : #6f8aff,
//   A400 : #a2b4ff,
//   A700 : #DBE7F7,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #000000,
//     600 : #000000,
//     700 : #000000,
//     800 : #000000,
//     900 : #000000,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   ));
// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $dark-primary: mat.m2-define-palette($mat-dark-cixblue);
// $dark-accent: mat.m2-define-palette($mat-dark-cixteal);

// // The warn palette is optional (defaults to red).
// $dark-warn: mat.m2-define-palette($mat-dark-cixred);

// // Create the theme object (a Sass map containing all of the palettes).
// // $dark-theme: mat.define-dark-theme($dark-primary, $dark-accent, $dark-warn);
// $dark-theme: mat.m2-define-dark-theme((color: (primary: $dark-primary,
//       accent: $dark-primary,
//       warn: $dark-warn,
//       teal: $dark-accent),
//     typography: $custom-typography-config,
//     density: 0,
//   ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.

// html,
// body {
//   background-color: var(--elevation-primary) !important;
//   color: var(--text-color);
// }

// body.dark-mode {
//   @include mat.all-component-themes($dark-theme);
// }

// TODO: this is the material 3 code to switch the colors and themes

@use 'sass:map';
@use '@angular/material'as mat;
@import url("https://fonts.googleapis.com/css?family=Space+Grotesk:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500&display=swap");
mat.$theme-ignore-duplication-warnings: true;


@include mat.core();

// Note: Color palettes are generated from primary: #0358CB
$_palettes: (primary: (0: #000000,
    10: #002456,
    20: #003377,
    25: #0358CB,
    30: #0358CB,
    35: #4390F8,
    40: #4390F8,
    50: #62A5FF,
    60: #8CBDFF,
    70: #B7D6FF,
    80: #DBE7F7,
    90: #F5F9FF,
    95: #F5F9FF,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  secondary: (0: #000000,
    10: #A51D08,
    20: #C33100,
    25: #F4460C,
    30: #F4460C,
    35: #FF6047,
    40: #FF6047,
    50: #FF8774,
    60: #FFBFB5,
    70: #FFDBD5,
    80: #FFE8E5,
    90: #FFF0EE,
    95: #eef0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  tertiary: (0: #000000,
    10: #3C017F,
    20: #5000AD,
    25: #640780,
    30: #7600FF,
    35: #7e2b9a,
    40: #9A43FF,
    50: #B16FFF,
    60: #CD8EFF,
    70: #DDB2FF,
    80: #E9CDFF,
    90: #F6ECFF,
    95: #F6ECFF,
    98: #fff7fb,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (0: #000000,
    10: #212121,
    20: #3F3F3F,
    25: #393b43,
    30: #3F3F3F,
    35: #A0A0A0,
    40: #A0A0A0,
    50: #BEBEBE,
    60: #D3D3D3,
    70: #E1E1E1,
    80: #EBEBEB,
    90: #F5F5F5,
    95: #f0f0fa,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
    4: #0c0e15,
    6: #11131a,
    12: #1d1f27,
    17: #272a31,
    22: #32353c,
    24: #373941,
    87: #d9d9e3,
    92: #e7e7f2,
    94: #ededf7,
    96: #f2f3fd,
  ),
  neutral-variant: (0: #000000,
    10: #212121,
    20: #3F3F3F,
    25: #393b43,
    30: #3F3F3F,
    35: #A0A0A0,
    40: #A0A0A0,
    50: #BEBEBE,
    60: #D3D3D3,
    70: #E1E1E1,
    80: #EBEBEB,
    90: #F5F5F5,
    95: #f0f0fa,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (0: #4B0000,
    10: #6B0505,
    20: #9E0000,
    25: #BF0000,
    30: #BF0000,
    35: #DA0000,
    40: #DA0000,
    50: #E32C2C,
    60: #F34545,
    70: #FF6565,
    80: #FF8787,
    90: #FFB2B2,
    95: #FFCBCB,
    98: #FFCBCB,
    99: #FFCBCB,
    100: #FFCBCB,
  ),
);

$_rest: (secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((color: (theme-type: light,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
    typography: (plain-family: Poppins,
      brand-family: 'Space Grotesk',
      bold-weight: 900,
      medium-weight: 500,
      regular-weight: 300),
  ));
$dark-theme: mat.define-theme((color: (theme-type: dark,
      primary: $_primary,
      tertiary: $_tertiary,
    ),
    typography: (plain-family: Poppins,
      brand-family: 'Space Grotesk',
      bold-weight: 900,
      medium-weight: 500,
      regular-weight: 300),
  ));



// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
body.light-mode {
  // @include mat.all-legacy-component-themes($light-theme);
  @include mat.all-component-themes($light-theme);

  background-color: $elevation-shadow-00-dp;
  color: var(--text-color)
}


html,
body {
  @media (prefers-color-scheme: dark) {
    background-color: var(--elevation-primary) !important;
    color: var(--text-color);
    @include mat.all-component-themes($dark-theme);
  }
}
