.icon-24 {
    height: 24px;
    width: 24px;
    font-size: 24px;
}

.icon-red {
    font-size: 20px;
    height: 20px;
    width: 20px;
    color: var(--error-color);
}