// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "./assets/sass/main.scss";

body {
  font-family: "Lato", sans-serif;
  padding: 0px;
  margin: 0px;
  letter-spacing: 0.25px;
}

.desktop-div {
  width: 50%;
  padding-left: 25%;
}

.outlet-container {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
}

// TODO make avatar a module and move this code in there
.avatar-background-img,
img {
  background-size: cover;
  background-position: top center;
}
