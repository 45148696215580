.loading.shimmer {

  p,
  span,
  button,
  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-hint,
  a {
    background: var(--shine-gradient-color);
    border-color: transparent !important;
    background-image: linear-gradient(to right, var(--shine-gradient-color) 0%, var(--shine-gradient-secondary-color) 20%, var(--shine-gradient-color) 80%, var(--shine-gradient-color) 100%) !important;
    background-repeat: no-repeat;

    color: transparent !important;

    box-shadow: none !important;

    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  .mat-mdc-form-field-outline {
    border-radius: 6px;
  }

  .mat-mdc-radio-container {
    border-radius: 100%;
  }

  // div.icon-div, 
  // div.desktop-icon-div,
  // div.back-text,
  // div.back-text-button {
  //   pointer-events: initial !important;
  //   cursor: pointer !important;

  //   & > {
  //     pointer-events: initial !important;
  //     cursor: pointer !important;
  //   }
  // }

  // Disabled/Hidden elements
  button,
  a,
  input,
  textarea,
  span,
  img,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  div,
  mat-mdc-icon,
  .mdc-notched-outline,
  p {
    pointer-events: none !important;
    cursor: not-allowed !important;
  }

  input,
  .avatar-initials {
    font-size: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-mdc-form-field-label mat-mdc-label,
  .mat-mdc-form-field-flex>* {
    color: transparent !important;

    &::after {
      color: transparent !important;
    }
  }

  .tooltip,
  div.avatar img,
  .mat-mdc-form-field img,
  .mat-mdc-form-field .mat-mdc-icon,
  .mat-mdc-form-field-suffix .mat-mdc-icon {
    display: none !important;
  }

  .ellipsis {
    text-overflow: initial;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
